import React from "react"
import Loadable from "@loadable/component"
import { useStaticQuery, graphql } from "gatsby"
import { globalHistory } from "@reach/router"
import SwaggerUIFallback from "../../components/swagger-ui-fallback"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

import "../../styles/swagger-ui.scss"

const SwaggerUI = Loadable(() => import("swagger-ui-react"), { ssr: false })

/*const DisableTryItOutPlugin = () => ({
  statePlugins: {
    spec: {
      wrapSelectors: {
        allowTryItOutFor: () => false,
      },
    },
  },
})*/

const APIs = () => {
  const { file, site } = useStaticQuery(
    graphql`
      query {
        file(
          sourceInstanceName: { eq: "apis" }
          relativePath: { eq: "helix.yml" }
        ) {
          publicURL
          spec: childApisYaml {
            openapi
            info {
              title
              version
              description
              termsOfService
              contact {
                email
                name
              }
            }
          }
        }
        site {
          siteMetadata {
            siteUrl
          }
        }
      }
    `
  )

  const origin = globalHistory.location.origin || site.siteMetadata.siteUrl
  const url = new URL(file.publicURL, origin)
  const { spec } = file

  return (
    <Layout className="api-docs">
      <SEO
        title={`${spec.info.title} OpenAPI Docs`}
        description={spec.info.description}
      />
      <SwaggerUI
        url={url.href}
        docExpansion="list"
        supportedSubmitMethods={["get", "post"]}
        //plugins={DisableTryItOutPlugin} turns off "try it" button
        fallback={<SwaggerUIFallback spec={spec} url={url.href} />}
      />
    </Layout>
  )
}

export default APIs
